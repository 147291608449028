import React from 'react'

import Layout from '../components/layout'

const Contact = () => {
    return (
      <Layout>
        <form className="w-full min-h-screen" action="https://formspree.io/f/mdopwroe" method="POST">
          <div className="">
            <label className="block uppercase tracking-wide text-red-100 text-md font-bold mb-2">
              Your Name
              <input
                className="appearance-none block w-full bg-gray-300 text-gray-700 border border-red-900 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="grid-name"
                type="text"
              ></input>
            </label>
          </div>
          <div>
            <label className="block uppercase tracking-wide text-red-100 text-md font-bold mb-2">
              Your Email
              <input
                className="appearance-none block w-full bg-gray-300 text-gray-700 border border-red-900 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="email"
                type="email"
                name="_replyto"
              ></input>
            </label>
          </div>
          <div>
            <label className="block uppercase tracking-wide text-red-100 text-md font-bold mb-2">
              Message
              <textarea
                className=" no-resize appearance-none block w-full bg-gray-200 text-gray-700 border border-red-900 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-64 resize-none"
                id="message"
                name="message"
              ></textarea>
            </label>
          </div>
          <button className="bg-white rounded my-4 px-4 py-2 border border-red-900" type="submit" value="Send">Send</button>
        </form>
      </Layout>
    )
}

export default Contact